import StartPageModel from 'Models/Pages/StartPage/StartPageModel.interface';
import ContentArea from 'DesignComponents/Organisms/ContentArea/ContentArea';
import HeroModule from 'DesignComponents/Organisms/Hero/HeroModule';
import useCurrentPage from 'Shared/Hooks/useCurrentPage';
import ContentContainer from 'DesignComponents/Molecules/ContentContainer/ContentContainer';
import { applyEditModeAttr } from 'Shared/Common/Helpers';

function StartPage() {
  const { contentArea, inEditMode, hero } = useCurrentPage<StartPageModel>();

  return (
    <>
      {hero && <HeroModule hero={hero} />}

      <ContentContainer {...applyEditModeAttr(inEditMode && 'MainContent')}>
        {Array.isArray(contentArea) && <ContentArea childItems={contentArea} />}
      </ContentContainer>
    </>
  );
}

export default StartPage;
